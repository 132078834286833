/* body{
    background-image: url('./assets/images/adref.png');
    background-size: cover;
} */
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

body,
html {
    font-size: 14px !important;
}
button :focus {
    outline: none;
}
button:disabled {
    cursor: no-drop !important;
}
*,
*:focus {
    outline: none;
}

:root{
    --std_gold : #d4ad18;
    --std_blue : #038daf;
    /* --std_teel : #0d404d; */
    --std_teel : #000000;
    --std_green : #26a65b;
    --std_red : #cd6c39;
}
/* font family */

@font-face {
    font-family: AVR;
    src: url("./assets/font/AvenirLTStd-Roman.otf");
}

@font-face {
    font-family: AV-HEAVY;
    src: url("./assets/font/Avenir-Heavy-05.ttf");
}

@font-face {
    font-family: AVPB;
    src: url("./assets/font/Avenir-Next-LT-Pro-Bold_5182.ttf");
}

@font-face {
    font-family: AVB;
    src: url("./assets/font/AvenirLTStd-Book.otf");
}

@font-face {
    font-family: AVL;
    src: url("./assets/font/AvenirLTStd-Light.otf");
}

@font-face {
    font-family: AVH;
    src: url("./assets/font/Avenir-Heavy.ttc");
}

body,
html {
    height: 100%;
    margin: 0;
    /* background-image: url('./assets/images/PersonalDetails-step1.png');
    background-size: cover; */
    padding: 0;
    background-color: #f8fafb !important;
    font-family: AVR !important;
    /* background-image: url('assets/images/AML_DASH.jpg'); */
}

/* scroll bar */

/* width */

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    visibility: hidden !important;
}

::-webkit-scrollbar:hover {
    background: #e2e2e2;
    width: 10px !important;
    height: 10px !important;
    visibility: visible !important;
}

/* Track */

::-webkit-scrollbar-track {
    /* // box-shadow: inset 0 0 5px $gray; */
    box-shadow: inset 0 0 6px #e2e2e2;
    -webkit-box-shadow: inset 0 0 6px #e2e2e2;
    border-radius: 4px;
    background: none;
    visibility: hidden !important;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background-color: #d4b033;
    height: 20px;
    width: 4px;
    transition: 0.5s linear all;
    transition-delay: 0.5ms;
    border-radius: 4px;
    visibility: visible !important;
}
:-moz-submit-invalid {
    box-shadow: none;
  }

  :-moz-ui-invalid {
    box-shadow:none;
  }

/* Handle on hover */

/* div::-webkit-scrollbar-thumb:hover
, pre::-webkit-scrollbar-thumb:hover
, .mat-dialog-content::-webkit-scrollbar-thumb
,.mat-card-content-area::-webkit-scrollbar-thumb{
    background: #d4b033;
    outline: 1px solid slategrey;
    width: 40px !important;
    height: 40px !important;
    visibility: visible !important;
} */
.json-code pre {
    overflow: visible;
}

::-webkit-scrollbar-thumb {
    background-color: #d4b033;
    /* outline: 1px solid slategrey; */
    width: 40px !important;
    height: 30px !important;
    /* visibility:  visible !important; */
}

textarea {
    resize: none !important;
}

.searchWrap .mat-form-field .mat-form-field-wrapper {
    margin-bottom: 0px !important;
    margin: 20px;
}

.tooltip-whitespace-handler {
    word-break: break-all !important;
}

.w-130px {
    width: 130px !important;
}
/* scroll bar ends */

/* material clock css starts here  */
.mat-clock .timepicker__header {
    background-color: rgb(212, 173, 24) !important;
}

.mat-clock .clock-face__clock-hand {
    background-color: rgb(212, 173, 24) !important;
}

.mat-clock .clock-face__number > span.active {
    background-color: rgb(212, 173, 24) !important;
}
.mat-clock .timepicker-button {
    color: rgb(212, 173, 24) !important;
}
.mat-clock .clock-face__clock-hand_minute::before {
    border: 4px solid rgb(212, 173, 24) !important;
}

/* material clock css ends */

/* 2Fa print  */

.highlighted {
    font-weight: 700;
    color: #007bff;
    cursor: pointer;
    display: inline;
}
.highLightedComment {
    font-weight: 900;
    color: rgb(212, 173, 24);
}

.h-170 {
    height: 170px !important;
    min-height: 170px !important    ;
}
span.UserAcNo {
    font-weight: 600;
    font-size: 12px;
    color: var(--std_teel);
}

.doc_add_validation {
    font-size: 12px;
    text-align: left !important;
    display: block;
    word-break: break-word;
}

.tooltipImg img {
    width: 16px;
    cursor: pointer;
}

.h-260px {
    overflow-y: scroll;
    min-height: 280px;
}

.h-44px {
    min-height: 44px;
    height: 44px;
}
/* .custom-table {
    border: 1px solid #dee2e6;
}

.custom-table th {
    padding: 10px;
    box-sizing: border-box;
}

.custom-table td  {
    padding: 10px;
    box-sizing: border-box;
} */

.closeDate {
    width: 10px;
}

* {
    font-family: AVB;
}

div,
button,
li {
    outline: none;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.LoginBg {
    background-attachment: fixed;
    /* background:url('assets/images/login-bg.svg'); */
    background-size: cover;
    background-repeat: no-repeat;
}

.LoginBg {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.outer_circle {
    height: 148px;
    margin-top: -93px;
    width: 148px;
    border-radius: 50%;
    margin-left: 27px;
    background: #f5f7f9;
}

.inner_circle {
    width: 79px;
    height: 79px;
    margin: 40px auto;
    background: #f1f4f6;
    border-radius: 50%;
}
.RED_BACKGROUND {
    background: red;
    text-align: center !important;
    color: white;
}
.WARNING_BACKGROUND {
    background: orange;
    text-align: center !important;
    color: white;
}
.mat-simple-snackbar-action > button{
    margin-top: 0px !important;
} 
.GREEN {
    background: green;
    text-align: center !important;
    color: white;
}
.WARN_BACKGROUND{
    background: red;
    text-align: center !important;  
    position: relative;
    padding-left: 45px !important;
}
.WARN_BACKGROUND::before{
    content: '';
    position: absolute;
    top: 13px;
    left: 15px;
    width: 20px;
    height: 20px;
    background-image: url('./assets/images/warning-board-white.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.LoginBg::after {
    content: "";
    width: 120px;
    border-radius: 50%;
    height: 120px;
    /* border-top-left-radius: 117px; */
    /* border-bottom-left-radius: 117px; */
    position: absolute;
    top: calc(50% - 50px);
    background: rgb(232, 236, 240);
    right: -60px;
    overflow: hidden;
    animation: Anim 5s infinite;
}

.LoginBg::before {
    content: "";
    width: 292px;
    border-radius: 50%;
    height: 292px;
    animation: Anim2 5s infinite;
    position: absolute;
    top: calc(50% - 136px);
    background: #f2f4f8;
    right: -145px;
    overflow: hidden;
}

.welcomeBg {
    background: url("assets/images/PilotScreen_Welcome.144.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.copyright {
    text-align: right;
}

.copyright.position-absolute {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.pl-15 {
    padding-left: 15px !important;
}

.mat-Secondary {
    background: #d4ae2a;
    color: #fff;
}
.mat-primaryCus {
    background: var(--std_teel);
    color: #fff;
    /* padding-bottom: 1px !important; */
}
.primaryStrCus {
    color: var(--std_teel);
}

.wizard {
    border: 1px solid #cccccc12;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(204, 204, 2014);
}

.tabnavbg {
    background: rgba(247, 247, 247, 0.56);
}

.wiz_title {
    display: flex;
    align-items: center;
}
.wiz_title span:nth-child(2) {
    font-size: 20px;
}

span.Badge {
    background: #d4ae2a;
    color: #fff;
    /* padding: 0px 5px; */
    width: 17px;
    height: 17px;
    margin-right: 10px;
    display: block;
    text-align: center;
    border-radius: 50%;
    font-size: 0.857em;
    position: relative;
    /* left: -5px; */
}

span.Badge p {
    font-size: 0.9285em;
    font-weight: normal;
    font-family: AVH;
}

.tabnav ul {
    padding-left: 10px;
    list-style: none;
}

.wizard .mat-tab-body-wrapper form {
    margin: 2% auto 2% auto;
}

.tabnav {
    width: 100%;
}

.tabnav ul > li:after {
    content: "";
    width: 15px;
    height: 15px;
    background: rgba(218, 217, 217, 0.69);
    position: absolute;
    right: 5px;
    top: 0px;
    border-radius: 50%;
}

.tabnav ul > li {
    position: relative;
    margin: 20px 0px;
    font-size: 0.9285em;
    color: var(--std_teel);
    padding-right: 20px;
}

.tabnav ul > li.active {
    font-weight: bold;
    font-family: AVR;
}

.tabnav ul > li.completed:after {
    background-image: url(assets/images/checked.svg);
    background-color: #fff;
    background-size: cover;
}

.tableMenu > li::before {
    content: "";
    width: 15px;
    height: 15px;
    background: rgba(218, 217, 217, 0.69);
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 50%;
}

.tableMenu > li {
    position: relative;
    margin: 20px 0px;
    font-size: 0.9285em;
    color: var(--std_teel);
    padding-left: 20px;
    font-family: AVR;
}

.tableMenu > li.completed::before {
    background-image: url(assets/images/checked.svg);
    background-color: #fff;
    background-size: cover;
}

/* .tableMenuTitle {
    color: #d4d4d4;
    padding-left: 11px;
    font-size: 0.857em;
    margin-bottom: 10px;
} */

/* change text color into white  */

.fontwhite {
    color: #ffffff;
}

/* set padding  */

.setPadding {
    padding-left: 100px;
    box-sizing: border-box;
}

.login .footerMnu {
    margin-left: 60px !important;
}

.tabInnerTitle > span {
    background: #e9eeef;
    padding: 4px 10px;
    font-size: 0.928em;
}

.tabInnerTitle {
    margin: 0;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 15px;
}

.row_tab {
    width: 65%;
    margin-left: 44px;
}

.welcomeBg h2 {
    font-size: 2.92em;
    font-weight: 600;
    /* margin-left: 25px; */
}

.yellow_square {
    position: absolute;
    width: 31px;
    height: 35px;
    /* left: 0px; */
    top: calc(50% - 13px);
    left: -15px;
    background: #d6b124;
}

.blue_rectangle {
    position: relative;
}

.blue_rectangle::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 20px;
    background: var(--std_teel);
    left: -60px;
}

.card_title {
    border-bottom: 1px solid rgba(212, 203, 203, 0.37);
    padding: 13px 15px 13px 15px;
    color: var(--std_teel);
    font-weight: 600;
}

.selector {
    text-align: center;
    border-radius: 4px;
    padding: 10px;
    background: #f8f9f9;
    cursor: pointer;
}

.selector p {
    display: inline;
    font-size: 11px;
    font-family: AVR;
    font-weight: 600;
    padding-left: 5px;
}

.selector image {
    width: 20px;
}

.selector.switch_color {
    background: var(--std_teel);
}

.selector.switch_color p {
    color: #fff;
}

.selector img {
    width: 25px;
    margin-right: 6px;
}
.userform {
    margin-top: 20px;
}

.user_edits {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0px 30px;
}

.user_edit_icons img {
    width: 20px;
    cursor: pointer;
}

.userData {
    border: 1px solid #ebe2e2b8;
    border-radius: 5px;
    overflow: auto;
}

.userData th {
    background: #f8f8f9;
    border-right: 1px solid #eee;
    font-size: 14px;
}

.userData tr td {
    background: #fff;
    border-right: 1px solid #ebe2e2b8;
}

.userData table {
    margin-bottom: 0px;
}

.user_edit_table {
    width: 100%;
}

.user_edit_table tr td {
    border: none;
}

.userData {
    margin-top: 20px;
}
.contact_person img {
    width: 20px;
    margin-right: 20px;
}
.tableRowEdit li {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px auto;
}
/* Material design Overriedes */

.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 1.6px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(182, 183, 183, 0.85);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: #d4ae2a;
}

.form_wizard .mat-ink-bar {
    height: 3px !important;
}

.form_wizard .mat-tab-label {
    font-family: AVR;
    color: #000;
    font-size: 1.071em;
    font-weight: 600;
}

.form_wizard .mat-tab-label-container {
    background: #fff;
}

.mat-form-field-label-wrapper {
    font-size: 0.928em;
}
.mat-form-field-label {
    padding-top: 1px;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
    color: var(--std_teel) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #d6b124 !important;
}

.iop th.mat-header-cell {
    text-align: left;
    border: 1px solid #e5ddddc7;
    /* background: #F8F8F9; */
    padding: 10px 10px;
}
.scrollling_table th.mat-header-cell {
    width: 185px;
    white-space: normal;
}

.iop .mat-cell {
    border: 1px solid #e5ddddc7;
    padding: 10px 10px;
}

.iop .mat-elevation-z8 {
    box-shadow: none;
}

.customMatelements .mat-card:not([class*="mat-elevation-z"]) {
    box-shadow: 1px 0px 1px -1px rgba(72, 67, 67, 0.19), 0 1px 1px 0 rgba(127, 125, 125, 0.14),
        0 1px 3px 0 rgba(204, 201, 201, 0.12);
}

.customMatelements .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
}

.iop .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
}

.iop .mat-form-field-underline,
.customMatelements .mat-form-field-underline.ng-tns-c7-0.ng-star-inserted {
    display: none;
}

.iop .mat-form-field-wrapper {
    margin-bottom: 0;
}

.iop .mat-form-field-appearance-legacy .mat-form-field-infix {
    border: none;
}

.customMatelements .mat-form-field-underline.ng-tns-c7-0.ng-star-inserted {
    display: none;
}

.customMatelements .mat-form-field-underline,
.risk_factor .mat-form-field-underline {
    display: none;
}
.hide_label label.mat-form-field-label {
    display: none !important;
}
.plainSelect .mat-form-field-infix {
    border: none;
}
/* @media only screen and (min-width: 768px) {
    .mat-tab-body-content, .mat-tab-body.mat-tab-body-active, .mat-tab-body, .mat-tab-body-wrapper {
        overflow: visible !important;
    }
} */

/* .mat-tab-body-content{
    overflow: visible !important;
} */

/* @-webkit-keyframes Anim {
    from {background-color: #f2f4f8;}
    to {background-color: rgb(232, 236, 240);}
  }


  @keyframes Anim2 {
    from {background-color: rgb(232, 236, 240);}
    to {background-color: #f2f4f8;}
  } */

.control_buttons,
.control_back_button {
    letter-spacing: 5px;
    font-weight: 600;
}

.addBtn {
    width: 25px;
    background: #d4ae2a;
    border-radius: 50%;
    padding: 0px 6px;
    border: 0;
    box-sizing: border-box;
    height: 25px;
    cursor: pointer;
}

.BusAddCnctBtn .addBtn {
    margin-top: 20px;
}

.BusAddCnct {
    width: calc(100% - 60px);
    float: left;
}

.addBtn > img,
button.editBtn > img,
button.delBtn > img {
    width: 100%;
    position: relative;
    top: -1px;
}

.BusAddCnctBtn {
    width: 30px;
    float: left;
}

button.delBtn {
    width: 25px;
    height: 25px;
    background: var(--std_teel);
    border: 0;
    border-radius: 50%;
    padding: 0px 7px;
    box-sizing: border-box;
    cursor: pointer;
}

button.editBtn {
    width: 25px;
    height: 25px;
    background: #d4ae2a;
    border: 0;
    border-radius: 50%;
    padding: 0px 7px;
    box-sizing: border-box;
    cursor: pointer;
}

.paraFont {
    font-size: 1em;
    text-align: left;
}

.register {
    margin-top: 10%;
    margin-left: 2%;
    background-color: #f2f5f6;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.register a {
    color: #d6b124;
}

.register p {
    margin-bottom: 0px;
    font-size: 1.0714em;
    font-weight: 600;
}

/* When the checkbox is checked, add a blue background */

.check_container input:checked ~ .checkmark {
    background-color: #f8fafb;
    border: none;
    background: #d4ae2a;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.check_container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.check_container .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rounded_yellow_button {
    background-color: #d4ae2a !important;
    padding: 0px 35px !important;
    color: #ffff;
}

.padding_zero{
    padding: 0 !important;
}
.yellow_button {
    background-color: #d4ae2a !important;
    /* padding: 0px 15px !important; */
    color: #ffff;
    font-size: 1.0714em;
}

.blue_button {
    /* background-color: var(--std_teel) !important; */
    background-color: var(--dynamic-bg-color, #0d404d);
    color: #ffff !important;
    font-size: 1.0714em;
}

.mat-primaryStrCus {
    border: 1px solid var(--std_teel) !important;
    color: var(--std_teel);
}

.copyright p {
    /* font-family: AVL; */
    font-size: 1em;
}

.footerMnu li {
    display: inline;
}

.footerMnu li:not(:first-child) {
    margin-left: 50px;
}

.footerMnu a,
.footerMnu a:hover,
.footerMnu a:focus {
    color: #717171;
}

.footerMnu {
    margin-top: 25px;
    padding-left: 0px;
}

ul.footerMnu.align-self-end.pl-15.mb-4 a {
    font-family: AVL;
    font-size: 1em;
}

table.mat-elevation-z8 {
    width: 100%;
}

.sidewrapper {
    width: 220px;
}

.contentWrapper {
    /* width: calc(100% - 200px); */
    width: 100%;
}

.sidewrapper,
.contentWrapper {
    float: left;
    padding: 0px 15px;
    box-sizing: border-box;
    overflow: auto;
}

.closeMenu {
    width: 60px !important;
}

.openContent {
    width: calc(100% - 50px) !important;
}

.px-15 {
    padding: 0px 15px !important;
}

.px-30 {
    padding: 0px 30px !important;
}

.passwordIndicator ul {
    list-style: none;
    padding: 0;
}

.passwordIndicator ul > li {
    position: relative;
    padding-left: 20px;
    margin: 10px;
}

.passwordIndicator ul > li::before {
    content: url(assets/images/valid.svg);
    position: absolute;
    left: 0;
    top: 2px;
}

.passwordIndicator ul > li.ValidPassword::before {
    content: url(assets/images/invalid.svg) !important;
}

.passwordIndicator {
    position: absolute;
    background: #f1f2b1;
    z-index: 9;
    padding: 15px;
    box-shadow: 0px 0px 3px 1px;
    display: none;
    font-size: 0.8571em;
}

.passwordIndicator {
    bottom: 35px;
    left: 100%;
    width: 330px;
}

.onFocusPopup:hover .passwordIndicator {
    display: block;
}

.ValidPassword {
    color: red;
}

.tabnav ul > li.uncompleted:after {
    background-image: url(assets/images/cross.png);
    background-color: #fff;
    background-size: cover;
}

@media only screen and (max-width: 767px) {
    .passwordIndicator {
        top: 100%;
        left: 30px;
        width: 330px;
        height: 183px;
    }
    .LoginBg::before {
        top: calc(50% - 119px);
        width: 230px;
        height: 230px;
    }
    .LoginBg::after {
        content: "";
        width: 95px;
        border-radius: 50%;
        height: 88px;
        top: calc(50% - 50px);
    }

    .welcomeBg {
        display: none;
    }
    .col-sm-12.com-md-2.col-lg-2.col-xl-2.tabnavbg::before {
        top: 0px;
    }
    .footerMnu li {
        font-size: 13px;
    }
    .footerMnu li:not(:first-child) {
        margin-left: 20px;
    }
    .copyright {
        text-align: center;
    }
}

@media only screen and (max-width: 425px) {
    .footerMnu li {
        font-size: 0.82857em;
    }
    .login .footerMnu {
        margin-left: 7px !important;
    }
}

.addBtn:disabled {
    background: #c1c1c1;
    cursor: no-drop;
}

.mat-form-field-wrapper {
    margin-bottom: 20px;
}

.customMatelements .mat-form-field-wrapper {
    margin-bottom: 0px;
}

/*  */

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background: #d6b124;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(208, 205, 206, 0.54);
}

.matMenuHeader ul {
    list-style: none;
    padding: 0px 10px;
    font-size: 0.9285em;
    margin: 0;
    color: var(--std_teel);
}

.matMenuHeader .icon {
    width: 30px;
    box-sizing: border-box;
    padding: 5px;
}

.mat-menu-content .iconContent:hover {
    color: #d3ae33;
}

.matMenuHeader .icon,
.matMenuHeader .iconContent {
    float: left;
    display: block;
}
.matMenuHeader ul ul {
    padding: 0px 10px;
}
.matMenuHeader li {
    margin: 5px auto;
    width: 100%;
    display: flex;
    /* align-items: center; */
    cursor: pointer;
    flex-direction: column;
}
.loginMenu ul {
    padding: 10px;
    margin: 0;
    list-style: none;
}
.loginMenu ul li {
    margin: 5px 0px;
}
.loginMenu .icon img {
    width: 15px;
    margin-right: 5px;
}
.level1Menu {
    font-weight: bold;
}
.main_title {
    padding: 20px 15px 0px 15px;
}

.main_title h3 {
    font-size: 1.7857em;
}

.tabnavbg::before {
    width: 100%;
    height: 53px;
    background: #f8f9f9;
    position: absolute;
    top: -50px;
    border-top-right-radius: 5px;
    content: "";
    left: 1px;
}

.tableSelect {
    width: 200px;
}

.tableInput {
    width: 75px;
}

.tableInputComment {
    width: 150px;
}

.closep button {
    transform: rotate(45deg);
}

.totalTaxRow {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
}

.totalTax {
    display: flex;
    flex-direction: column;
    float: right;
    padding: 20px 10px;
    color: var(--std_teel);
    font-size: 0.9285em;
}

.totalTaxLabel {
    width: 200px;
}

.totalTaxData {
    font-weight: bold;
}

.dot_indicators ul li {
    position: relative;
    display: flex;
    margin-right: 30px;
    align-items: center;
}
.legend-text {
    font-size: 12px;
}

.legend-line-height {
    line-height: 26px !important;
}

.dot_indicators ul li::before {
    content: "";
    height: 13px;
    width: 13px;
    background: #d8b53c;
    position: absolute;
    border-radius: 50%;
}

.dot_indicators ul li:nth-child(1)::before {
    background: #ec644b;
}

.dot_indicators ul li:nth-child(2)::before {
    background: #d8b53c;
}

.dot_indicators ul li:nth-child(3)::before {
    background: var(--std_green);
}

.dot_indicators ul li:nth-child(4)::before {
    background: #3498db;
}

.dot_indicators ul {
    display: flex;
    /* padding-lef{t: 0px; */
    list-style: none;
}

.dot_indicators p {
    margin-bottom: 0px;
    margin-left: 24px;
}

.redbr {
    border-left: 4px solid #ec644b !important;
}

.greenbr {
    border-left: 4px solid #26a65b !important;
}

.goldbr {
    border-left: 4px solid #d8b53c !important;
}

.margin-row {
    margin: 0 -15px;
}

.wizard.margin-row {
    box-shadow: none !important;
}

.wizard.margin-row .mat-tab-labels {
    display: flex;
    padding: 2px 0px;
    background: #fafafa;
}

.wizard.margin-row .mat-tab-label-container {
    background: none;
}

.titleCardSelect .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
}

.titleCardSelect .mat-form-field-underline,
.titleCardSelect .mat-form-field-label-wrapper {
    display: none;
}

.titleCardSelect {
    border: 1px solid #e0e0e0;
    background: #f8f9f9;
    border-radius: 2px;
    padding: 0px 10px;
}

.titleCardSelect .mat-form-field-infix {
    padding: 10px 0px 10px 0px;
    border-top: 0;
    font-size: 12px;
}

.card_head {
    border-bottom: 1px solid rgba(212, 203, 203, 0.37);
    padding: 0px 15px 10px 15px;
    color: var(--std_teel);
    align-items: center;
    font-weight: 600;
}

.text-shadow-black {
    text-shadow: 0px 0px #000000;
}

.risk_factor .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
}

.risk_factor .mat-form-field-wrapper {
    margin-bottom: 0px;
}

.dot_ind ul li {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.dot_ind ul li::before {
    content: "";
    height: 13px;
    width: 13px;
    background: #d8b53c;
    position: absolute;
    border-radius: 50%;
}

.dot_ind ul li:nth-child(1)::before {
    background: #ed554c;
}

.dot_ind ul li:nth-child(2)::before {
    background: #d6b124;
}

.dot_ind ul li:nth-child(3)::before {
    background: var(--std_green);
}
.dot_ind ul li:nth-child(4)::before {
    background: #3698db;
}
.dot_ind ul li:nth-child(5)::before {
    background: #cccccc;
}

.dot_ind ul {
    display: flex;
    padding-left: 5px;
    list-style: none;
    margin-bottom: 0;
    justify-content: space-between;
}

.dot_ind p {
    margin-bottom: 0px;
    padding-left: 17px;
    margin-top: 1px;
}

.bgWhite {
    background: #fff;
}

.gridCtrls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.gridCtrls .img_button img {
    width: 37px;
    cursor: pointer;
}

.gridCtrls .img_button {
    background: none;
    border: none;
}

.gridCtrls .refresh_button {
    border:  0.5px solid rgb(139 139 139 / 22%);
    border-radius: 4px;
    padding: 0px 5px;
}

.gridCtrls .img_button button {
    background: none;
    border: none !important;
}

.srchGroup {
    display: flex;
    position: relative;
}

.srchTextbox input {
    border: 1px solid #ccc;
    padding: 4px 0px 4px 33px;
    border-radius: 2px;
}

.checkBoxlign .mat-checkbox-layout {
    position: relative;
    top: 3px;
}

.pointer {
    cursor: pointer;
}

.red {
    background: #ec644b;
    color: #ffffff !important;
}

.yellow{
    background: #D1A700;
    color: #ffffff !important;
}

.green {
    background: var(--std_green);
    color: #ffffff !important;
}
.green_text {
    color: var(--std_green);
}

.ash {
    background: #d0cece;
}
.orange {
    background: #f47f33;
    color: white;
}
.blue {
    background: #3498db;
}

.gold {
    background: #d6b124;
}

.ash {
    background: #d0cece;
}

.red_color {
    background: #ec644b;
}

.voided_red {
    background: #ec644b;
}
.pale_gold {
    background: #fde599;
}

.creamed {
    background: #b78a46;
}
.status {
    text-align: center;
    padding: 7px 6px;
    color: #fff;
    border-radius: 2px;
}

.bs {
    width: 40px;
    height: 40px;
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    line-height: 40px;
}

.mat-table__wrapper .mat-table {
    min-width: auto !important;
    width: 100% !important;
}

.mat-header-row {
    width: 100%;
}

.mat-row {
    width: 100%;
}

.divider {
    border-bottom: 1px solid #ccc;
    display: block;
    margin-right: -39px;
    margin-left: -39px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.popupTitle {
    color: var(--std_teel);
    font-size: 1.285em;
    font-weight: bold;
}

/* round checkbox */

.comply_parent .mat-form-field-wrapper {
    margin-bottom: 0px !important;
}

.comply_parent form {
    margin-bottom: 0px !important;
}

.comply_parent.container-fluid.profiles {
    padding-left: 25px;
    padding-right: 25px;
}
.comply_parent .semibold{
    font-size: 1em;
}
.comply_parent >h4{
    margin-bottom: 18px;
}
.col-sm-18 {
    width: 18%;
}

.mat-elevation-z8 {
    box-shadow: none;
}

.mat-table.mat-table {
    border: 1px solid #f8f0f0;
}

th.mat-header-cell {
    border: 1px solid #f8f0f0;
    font-size: 1em;
    font-weight: 600;
    color: var(--std_teel) !important;
    padding: 10px !important;
    background: #fafafb;
}
th.heading_table_th {
    padding: 0px !important;
}

.heading_table tbody tr:first-child th {
    padding: 8px 0px;
}
.heading_table tbody tr:nth-child(2) th {
    padding: 5px 0px;
    border: 1px solid #eee;
}
.mat-cell {
    border: 1px solid #f8f0f0;
    padding: 10px !important;
}

.detail_container {
    margin-bottom: 17px;
    font-size: 1.071em;
}

.detail_container > p:nth-child(1) {
    color: #c3c0c0;
    margin-bottom: 5px;
}

.details .detail_container:nth-child(4) {
    margin-bottom: 28px;
}

.detail_container p:nth-child(2) {
    color: var(--std_teel);
    margin-bottom: 0px;
}

.status_single {
    padding: 13px 32px 13px 32px;
    background: #fcfdfd;
    border-bottom: 1px solid #eee;
}

.date_container h4 {
    margin-bottom: 2px;
}

.date_container p {
    color: #c3c0c0;
    margin-bottom: 0px;
}

.user_current_stat {
    text-align: right;
}

.incomplete {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e5e5e5;
}

.users_mod .mat-row {
    height: auto !important;
}

.users_mod .mat-cell {
    padding: 5px 10px 5px 8px !important;
}

.BrdCmb {
    background: #fafafa;
    padding: 15px 30px;
    border-left: 1px solid #e9e7e7;
    border-bottom: 1px solid #e9e7e7;
    justify-content: flex-end;
}

.BrdCmb .BackBtn {
    color: #207c93;
    cursor: pointer;
}

.BrdCmb .BackBtn:before {
    content: "<";
    margin-right: 5px;
}

.table-responsive.users_mod {
    overflow-y: hidden;
}

.striped .mat-row:nth-child(even) {
    background-color: #fff;
}

.striped .mat-row:nth-child(odd) {
    background-color: #eee;
}

.checkbox_width {
    width: 55px !important;
    text-align: center !important;
}
.srchGroup {
    display: flex;
    position: relative;
}
.srchIcon img {
    width: 33px;
    padding: 10px 9px;
    position: absolute;
    right: 0;
    top: 0;
    /* background: #ccc; */
    cursor: pointer;
}
.srchTextbox input {
    border: 1px solid #ccc;
    padding: 8px 33px 8px 8px;
    border-radius: 2px;
    font-size: 13px;
}
/* table td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */
.fixed_layout {
    table-layout: fixed;
}
.fixed_layout_table {
    table-layout: fixed;
}

@media only screen and (min-width: 2000px) {
    body,
    html {
        font-size: 28px;
    }
    .sidewrapper {
        width: 400px;
    }
}
.CloseModel {
    text-align: right;
    color: #ccc;
    cursor: pointer;
    font-size: 20px;
}
.choosebutton {
    background: #d4ad18;
    border: none;
    padding: 8px 25px;
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    position: relative;
}
.fileUploader {
    width: 100%;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fileUploader input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ec644b;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.close {
    text-align: right;
    width: 100%;
}
.innerWizard .mat-tab-label-container,
.innerWizard .mat-tab-header {
    display: none;
}
.innerTab > .mat-tab-group > .mat-tab-label-container,
.innerTab > .mat-tab-group > .mat-tab-header {
    display: none;
}
.mat_tab_group {
    overflow: hidden;
}
.mat-tab-label {
    font-family: AVR;
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

/* .tableRowEdit .cloneRow::before {
    content: url(assets/images/ink-pad.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
} */

/* .tableRowEdit .morerow::before {
    content: url(assets/images/vieweye.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .deleterow::before {
    content: url(assets/images/deletesmall.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
} */
/* .tableRowEdit .history::before {
    content: url(assets/images/history.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
} */

.action_array .read {
    background: url("assets/images/read.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .unread {
    background: url("assets/images/unread.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .unflaged {
    background: url("assets/images/unflag.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .flagged {
    background: url("assets/images/flagged.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}

.action_array .edit_row {
    background: url("assets/images/blue_pencil.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .edit_row_disable {
    background: url("assets/images/blue_pencil_dect.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .edit {
    background: url('assets/images/editico.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .attach {
    background: url("assets/images/attchments.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .download_row {
    background: url("assets/images/download.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .view_row {
    background: url("assets/images/row_view.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .share_row {
    background: url("assets/images/share.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    padding: 0px;
    /* background: red; */
    min-width: auto;
}
.action_array .resend {
    background: url("assets/images/resend.svg");
    background-size: 100% 100%;
    min-width: 23px;
    height: 23px;
    padding: 0pc;
}
.action_array .row_send {
    background: url("assets/images/email_send.svg");
    background-size: 100% 100%;
    min-width: 23px;
    height: 23px;
    padding: 0pc;
}

.action_array .reset {
    background: url("assets/images/reloadFW.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}

.action_array .archive {
    background: url('assets/images/archive-icon.png');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .restore {
    background: url('./assets/images/restore.png');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .delete {
    background: url("assets/images/dump.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .add {
    background: url("assets/images/rowadd.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .save {
    background: url("assets/images/save.png");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .ach {
    background: url("assets/images/ach.png");
    background-size: 100% 100%;
    min-width: 28px;
    height: 28px;
    padding: 0pc;
}
.action_array .csv {
    background: url("assets/images/csv.png");
    background-size: 100% 100%;
    min-width: 28px;
    height: 28px;
    padding: 0pc;
}
.action_array .bai {
    background: url("assets/images/bai.png");
    background-size: 100% 100%;
    min-width: 28px;
    height: 28px;
    padding: 0pc;
}
/* .action_array .history {
    background: url('assets/images/history.svg');
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}


. */
.action_array .cancel {
    background: url("assets/images/close.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .row_cancel {
    background: url("assets/images/row_cancel.svg");
    background-size: 100% 100%;
    min-width: 16px;
    height: 20px;
    padding: 0pc;
}
.action_array .approve {
    background: url("assets/images/approve.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .reject {
    background: url("assets/images/reject.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .comment {
    background: url("assets/images/blogging.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.action_array .history {
    background: url("assets/images/clock.svg");
    background-size: 100% 100%;
    min-width: 20px;
    height: 17px;
    padding: 0pc;
}
.pageHeaderCnr .refresh-order {
    background: url("assets/images/refresh.svg");
    background-size: 100% 100%;
    height: 35px;
    width: fit-content;
    padding: 0px;
}

.action_array .download {
  background: url('assets/images/download.svg');
  background-size: 100% 100%;
  min-width: 20px;
  height: 17px;
  padding: 0pc;
}
.action_array .view {
  background: url('assets/images/vieweye.svg');
  background-size: 100% 100%;
  min-width: 20px;
  height: 17px;
  padding: 0pc;
}

.pageHeaderCnr .refresh{
  margin-right: 85px;
  padding-top: 8px;
}

.action_array button {
    margin-right: 5px;
}
.action_array.cardIcons .comment,
.action_array.cardIcons .history {
    width: 30px;
    height: 30px !important;
    margin-right: 15px;
}
.dot::after {
    content: "....";
}
.mat_cust_head,
.card_head {
    padding: 15px;
    color: #000;
    border-bottom: 1px solid #ccc;
}
.head_with_select,
.card_head {
    padding: 7px 15px;
}

.mat_cust_head .mat-card-title,
.card_head .mat-card-title {
    margin-bottom: 0px !important;
}
.cust_card .mat-card-content {
    padding: 15px;
}
.wht-nowrap {
    white-space: nowrap;
}
.system_detail_group {
    padding: 20px 0px;
    border-bottom: 1px solid #eee;
}
.system_detail_group .system_detail_single:nth-child(1) {
    color: #c3c0c0;
}
.system_detail_group .system_detail_single:nth-child(2) {
    word-break: break-all;
    white-space: pre-wrap;
}
.sys_details .mat-card {
    padding: 0px 16px;
}
.gearIcon {
    background-image: url(assets/images/gear.png);
    width: 25px;
    height: 25px;
    background-size: 17px 17px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: transparent;
}
.page_title,
.popupTitle_rules {
    color: var(--std_teel);
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
.page_title_collapse,
.popupSubTitle_rules {
    color: var(--std_teel);
    font-size: 15px;
    font-weight: bold;
    margin: 0;
}

.page_title_not_verified {
    color: #f23d3d !important;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.btnclose {
    background-image: url(./assets/images/close-gray.svg);
    width: 20px;
    height: 20px;
    background-size: 14px 14px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border: 0;
    background-color: transparent;
    transition: all 1s ease;
}
.btnclose:hover {
    background-image: url(assets/images/close.svg);
}
.ruleModal .mat-dialog-container {
    background: #f8fafb;
}
.bold {
    color: var(--std_teel);
    font-size: 1.4285em;
    font-weight: bolder;
}
.semibold {
    color: var(--std_teel);
    font-size: 1.328em;
    font-weight: 400;
}
.center_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mat-card-header .mat-card-title {
    font-size: unset;
}
.mat-card-header .mat-card-title .bold {
    margin-bottom: 0px;
}
.mat-card-header-text {
    width: 100%;
}
.progress_container {
    position: relative;
    width: 10%;
    max-width: 100%;
    height: 50px;
    background: #fff;
    border-right: 1px solid #eee;
}

.mat-cell.progress_ {
    padding: 0px !important;
    border: 0px !important;
}
span.filterIcon img {
    width: 100%;
}
span.filterIcon {
    width: 28px;
    padding: 5px;
    display: block;
}
.rule-title {
    display: flex;
}
.border-y {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #e6e6e6;
}
.border-t {
    border-top: 1px solid;
    border-color: #e6e6e6;
}
.border-r {
    border-right: 1px solid;
    border-color: #e6e6e6;
}
.grayFont {
    color: #ccc;
}
.blueFont {
    color: var(--std_teel);
        word-break: break-all;
}
.line-height-0 {
    line-height: 0;
}
.w-50px {
    width: 50px;
}

.pageHeaderCnr {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    /* padding-top: 1px; */
    /* padding-bottom: 12px; */
    min-height: 52px;
    align-items: center;
    display: flex;
}
.btnBack {
    border: 1px solid #ccc !important;
}
.btnBack:before {
    content: "<";
    margin-right: 6px;
}
.lnkViewSettings,
.lnkShowHistory {
    color: var(--std_teel);
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
}
.lnkViewSettings:before,
.lnkShowHistory:before,
.notification:before {
    content: "";
    width: 25px;
    height: 25px;
    display: inline-block;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: -3px;
}
.lnkViewSettings:before {
    background-image: url(assets/images/gear.png);
}
.lnkShowHistory:before {
    background-image: url(assets/images/historyicon.svg);
}
.notification:before {
    background-image: url(assets/images/warning-sign.svg);
}
.notification {
    cursor: pointer;
    position: relative;
}

.mat-exp-custom .mat-expansion-panel-body {
    padding: 0 !important;
}
.popup_header {
    border-bottom: 1px solid #ccc;
    margin-left: -24px;
    margin-right: -24px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 10px;
    margin-top: -10px;
}
.mat-cell.double_status {
    padding: 0px !important;
}
.mat-cell.double_status table tbody th:nth-child(1) {
    border-right: 1px solid #f8f0f0;
}
.desc_period {
    color: green;
}
.desc_rule {
    color: red;
}
span.status_cnr {
    display: block;
    padding: 5px 10px;
    text-align: center;
    border-radius: 3px;
    color: #fff;
}
.w-150px {
    width: 150px;
}
.w-200px {
    width: 200px;
}
.w-250px {
    width: 250px;
}
.w-18 {
    width: 18% !important;
}
.w-100px {
    width: 100px !important;
}

.w-60px {
    width: 60px !important;
}
.w-120px {
    width: 120px !important;
}
.w-140px {
    width: 140px !important;
}
.MXW-600px {
    max-width: 600px;
}
.br-1px {
    border-right: 1px solid #efecec;
}

.brown_border {
    border: 1px solid #dad8d8ee !important;
}

.closePopup {
    right: 0;
    top: 0;
    z-index: 9;
}
.tabRule ul {
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #ccc;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 50px;
}
.tabRule ul > li {
    display: inline-flex;
    color: var(--std_teel);
    font-size: 16px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: 0.5s border ease 0s;
}
.tabRule ul > li:not(:last-child) {
    margin-right: 10px;
}
.tabRule ul > li.active {
    font-weight: bold;
    border-bottom: 2px solid var(--std_teel);
}
th .innerTable th {
    padding: 5px 10px;
    text-align: center;
}
td .innerTable td {
    padding: 13px 10px;
    border-right: 1px solid #ccc;
    text-align: center;
}
/* th .innerTable {
    margin: -10px;
} */
th .innerTable tr:first-child th:first-child {
    border-bottom: 1px solid #e5e5e5;
}
th .innerTable tr:last-child th {
    border-right: 1px solid #e5e5e5;
}
th.mat-header-cell.padding_0,
.mat-cell.padding_0 {
    padding: 0 !important;
}
td .p5-10px td div {
    padding: 5px 10px;
}
.w-180 {
    width: 180px;
}
.mat-menu-panel {
    max-width: 400px !important;
}
.mat-menu-panel .main_settings {
    min-width: 190px !important;
}
.bgGray {
    background: #f1f1f1;
}
.matMenuHeader {
    margin: -8px 0;
    /* min-width: 390px; */
}
.matMenuHeader ul {
    padding: 10px 0;
}
.w100px {
    width: 100px;
}
/* .mini_title {
    font-size: 1em;
    font-weight: 600;
    color: var(--std_teel) !important;
} */
/* .border_box_parent {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #f7f4f4;
}
.border_box_parent .border_box {
    border-bottom: 1px solid #f7f4f4;
    padding: 0px;
} */
/* .border_box p {
    margin: 0px 2px;
}
.border_box > div {
    text-align: right;
} */
.import_button {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0pc;
    opacity: 0;
}
.ruleCtrl .mat-form-field-wrapper {
    margin-bottom: 0;
}
.w-180 {
    width: 180px;
}
.tableInputs select,
.tableInputs input[type="text"] {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #ccc;
}
.tableInputs select:disabled,
.tableInputs input[type="text"]:disabled {
    border-color: transparent;
    background: none;
    -webkit-appearance: none;
    color: #000;
}
.tableInputs select {
    padding: 3px 2px;
}
.tableInputs input[type="text"] {
    padding: 2px 2px;
}
.Investor {
    background: #d8b53c;
    line-height: 22px;
    display: block;
    font-size: 12px;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    border-radius: 50%;
    font-weight: 600;
    color: #fff;
    margin-top: 4px;
}
.Bo {
    background: #32ff7e;
    line-height: 22px;
    display: block;
    font-size: 12px;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    border-radius: 50%;
    font-weight: 600;
    color: #fff;
    margin-top: 4px;
}
.controller {
    background: #3c40c6;
    line-height: 22px;
    display: block;
    font-size: 12px;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    border-radius: 50%;
    font-weight: 600;
    color: #fff;
    margin-top: 4px;
}
.types_circle span span {
    margin-right: 10px;
}
.formTitle {
    color: var(--std_teel);
    font-size: 17px;
    font-weight: bold;
}
.editTableCntrols .mat-form-field-wrapper {
    margin: 0;
}
.editTableCntrols .mat-form-field-infix {
    border-top: 0px;
}
.editTableCntrols .mat-form-field {
    margin: -20px 0px -40px 0px;
}
:host ::ng-deep .black-overlay {
    background-color: rgba(51, 51, 51, 0.66) !important;
    position: fixed !important;
}
:host ::ng-deep .overlay {
    background-color: rgba(51, 51, 51, 0.66) !important;
    position: fixed !important;
    height: 100%;
}

.AddressTitle {
    text-decoration: underline;
    font-weight: bold;
}
.absolute_back_button {
    position: absolute !important;
    z-index: 100;
    background: #fafafa;
    top: 5px;
    right: 10px;
}

.absolute_refresh_button {
    position: absolute !important;
    z-index: 100;
    top: 5px;
    right: 90px;
}
.locked {
    cursor: pointer;
    width: 45px;
}
.unlockmenu img {
    width: 25px;
}
.unlockmenu .mat-menu-content {
    padding: 0px !important;
}
.minimal_icon_try {
    width: 100px;
}
.editTableCntrols .mat-form-field-wrapper {
    margin: 0;
}
.editTableCntrols .mat-form-field-infix {
    border-top: 0px;
}
.mandatoryStar {
    color: red;
}
.card_individual .status-card {
    padding: 25px 15px;
    height: 100%;
    min-height: 140px;
    border-radius: 6px;
    box-shadow: -1px 1px 5px -2px rgba(89, 86, 89, 1);
}
.card_individual .node-block {
    padding: 25px 10px;
    border-radius: 6px;
    box-shadow: -1px 1px 5px -2px rgba(89, 86, 89, 1);
}

.card_individual .status-card:nth-child(1) {
    background: var(--std_red);
    color: #fff;
}

.card_individual .status-card:nth-child(2) {
    background: var(--std_blue);
    color: #fff;
}
.card_individual .card-wrap:nth-child(1) .status-card {
    background: var(--std_red);
    color: #fff;
}

.card_individual .card-wrap:nth-child(2) .status-card {
    background: var(--std_blue);
    color: #fff;
}

.card_individual .card-wrap:nth-child(3) .status-card {
    background: var(--std_teel);
    color: #fff;
}
.card_individual .card-wrap:nth-child(4) .status-card {
    background: var(--std_gold);
    color: #fff;
}
.card_individual .card-wrap:nth-child(5) .status-card {
    background: #289372;
    color: #fff;
}

.card_individual .status-card img {
    width: 25px;
    margin-bottom: 15px;
}

.card_individual h3 {
    font-weight: 600;
}

.break_flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.break_flex_around {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.no-drop {
    cursor: no-drop;
}

.row.card_individual.break_flex {
    padding: 26px 0px;
}
.block-chains .mat-tab-label {
    height: auto !important;
    padding: 0px 24px 0px 0px;
}

.block-chains .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: transparent;
}
.block-chains .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border: transparent;
}
.block-chains .mat-elevation-z4 {
    box-shadow: none;
}
.block-chains .mat-tab-labels {
    margin: 24px 0px !important;
}
.block-chains .mat-tab-header {
    border: none;
}
.mat_cust_head,
.card_head {
    padding: 10px;
    color: #000;
    border-bottom: 1px solid #ccc;
}

.mat_cust_head .mat-card-title,
.card_head .mat-card-title {
    margin-bottom: 0px !important;
}
.card_head .mat-card-header-text {
    margin: 0 !important;
}
.mat_cust_head .mat-card-header {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.mat-card-avatar.button-container {
    width: unset !important;
}
.w120-container {
    width: 120px !important;
}
.btnctDct {
    min-width: 100px !important;
}
.tableRowEdit .editRow::before {
    content: url(assets/images/editico.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .addRow::before {
  content: url('./assets/images/add-dash-small.svg');
  width: 8px;
  height: 15px;
  position: relative;
  margin-right: 8px;
  top: -2px;
}

.tableRowEdit .viewRow::before {
  content: url(assets/images/eye-blue.svg);
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 4px;
  top: -2px;
}

.tableRowEdit .deleteRow::before {
  content: url(assets/images/deletesmall.svg);
  width: 15px;
  height: 17px;
  position: relative;
  margin-right: 4px;
  top: -2px;
}

.tableRowEdit .cloneRow::before {
    content: url(assets/images/ink-pad.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .morerow::before {
    content: url(assets/images/vieweye.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .ach::before {
    background: url(assets/images/ach.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    width: 24px;
    height: 25px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .bai::before {
    background: url(assets/images/bai.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    width: 24px;
    height: 25px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .endCancel::before {
    content: url(assets/images/ban-solid.svg);
    width: 23px;
    height: 23px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .deleterow::before {
    content: url(assets/images/deletesmall.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .history::before {
    content: url(assets/images/history.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .dispute::before {
    content: url(assets/images/mace.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .approveRow::before {
    content: url(assets/images/rowapprove.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .cancel::before {
    content: url(assets/images/closesm.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

.tableRowEdit .rowReject::before {
    content: url(assets/images/rowreject.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .voidDoc::before {
    content: url(assets/images/void.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .approval::before {
    content: url(assets/images/verification-symbol-of-a-man.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.tableRowEdit .approvalCancel::before {
    content: url(assets/images/cancelApprove.svg);
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}
.reg-row{
    position: relative;
    padding-left: 20px !important;
}
 .reg-row::before {
    content: '';
    background-image: url(assets/images/clipboard.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 2px;
    left: 0px;
}

ul.tableRowEdit {
    margin: 0;
    list-style: none;
    padding: 6px 10px;
}
.restrictedBusiness mat-card {
    min-height: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 2em;
    color: var(--std_teel);
    cursor: pointer;
}
.restrictedBusiness {
    margin: 30% auto;
    text-align: center;
}
img.table_head_back {
    width: 10px;
}
.large_menu.mat-menu-panel {
    max-width: 585px !important;
}

.table_header.row {
    background: #fff;
    padding: 14px 13px;
    border-bottom: 1px solid #eee;
}

.table_details.row {
    padding: 14px 10px;
    background: #f8f8f9;
    color: #000000;
}
.bankedits img {
    width: 20px;
}
.table_outer {
    box-shadow: 0px 3px 3px 0px #eee;
}
.btnGrp textarea {
    width: 100%;
}
.btnGrp {
    display: flex;
}
.btnGrp button {
    width: 140px;
}
.wd-500 {
    width: 500px;
}
.wd-450 {
    width: 450px;
}
.wd-200 {
    width: 200px;
}
.flex-align-end {
    align-items: flex-end;
}
.card_icons:hover {
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    transform: translate3d(0, 0, 0);
    transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.card_icons span {
    font-size: 18px;
}
.standcUser .standcImg {
    width: 30px;
    padding: 0px 5px;
    margin-right: 5px;
}
.standcUser .standcImg img {
    width: 100%;
}
.standcUser {
    display: flex;
    align-items: center;
}
.card_sub_title {
    color: #c4bdbd;
    font-family: AVH;
    margin-bottom: 0px;
}
.mat_cust_haed,
.card_head {
    padding: 10px 0px 10px 15px;
    color: #000;
    border-bottom: 1px solid #ccc;
}
.mat_cust_haed .mat-card-title,
.card_head .mat-card-title {
    margin-bottom: 0px !important;
}
.card_head .mat-card-header-text {
    margin: 0 !important;
}
.mat_cust_haed .mat-card-header {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.card_head .mat-card-avatar {
    height: 20px;
}

.maingear.large_menu {
    min-width: 600px !important;
}
.twofa_user {
    display: flex;
    align-items: center;
}
.gridCtrls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}
.shake_anim {
    animation: shake 1.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-iteration-count: 8;
}
.shake_anim:hover {
    animation: none !important;
}
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
        /* transform: scale(1.1); */
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
        /* transform: scale(1); */
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
        /* transform: scale(1.1); */
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
        transform: scale(1.2);
    }
}

.gridCtrls span > img {
    width: 37px;
    cursor: pointer;
}
.twofa_user > span {
    width: 35px;
    padding: 0px 5px;
    margin-right: 5px;
}
.twofa_user img {
    width: 100%;
}
.angle {
    border: 0;
    width: 10px;
    height: 10px;
    margin: 3px;
    background-color: transparent;
    background-size: 100%;
    background-position: center;
}
.angle.up {
    background-image: url(assets/images/up-arrow.svg);
}
.angle.down {
    background-image: url(assets/images/angle-arrow-down.svg);
}
.termsTitle {
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: var(--std_teel);
    text-decoration: underline;
}
.termsAlert {
    padding-top: 5px;
    font-size: 12px;
    color: var(--std_teel);
}
.termsAlert :before {
    background: url(assets/images/warning-board.png) no-repeat 0px 4px;
    display: inline-block;
    width: 20px;
    height: 25px;
    content: "";
    background-size: 100%;
    vertical-align: -15%;
}
.icon_and_detail,
.icon_and_detail_intable {
    display: flex;
    align-items: center;
}
.icon_and_detail > p {
    margin: 0 !important;
}
.tab_icon img {
    width: 25px;
}
.icon_and_detail_intable img {
    width: 100%;
}
.icon_and_detail_intable > span {
    width: 25px;
    display: block;
}
.CloseModel img {
    width: 15px;
    cursor: pointer;
}
.userTitleWrap {
    display: flex;
    width: calc(100% - 104px);
    padding-left: 10px;
    align-items: center;
}
.userEmailWrap {
    display: flex;
}
.userIcons {
    margin-left: auto;
    width: 100px;
    text-align: right;
}
.backBtnPadding .mat-tab-header {
    padding-right: 95px;
}
.long_text_container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.long_text_container.unwidth {
    width: unset !important;
}
.mini_back img {
    width: 15px;
}
.terms {
    margin-bottom: 35px;
    border-bottom: 1px dashed #b5b0b0;
    padding-bottom: 14px;
}
.terms span {
    text-align: justify;
    display: block;
    /* word-break: break-all; */
    font-size: 12.8px;
}
.noAnimate .mat-slide-toggle-bar,
.noAnimate .mat-slide-toggle-thumb-container,
.noAnimate .mat-slide-toggle-thumb,
.noAnimate .mat-slide-toggle-bar {
    transition: none !important;
    transition-delay: none !important;
}
.countOuter {
    width: 25px;
}
.counterText {
    width: calc(100% - 25px);
    padding-left: 5px;
}
.countValue {
    background: #e00000;
    color: #fff;
    width: 22px;
    padding: 3px;
    border-radius: 50%;
    text-align: center;
    height: 22px;
    font-size: 11px;
    font-weight: bold;
    border: 1px solid #d60808;
}

.addnewopt {
    color: #d4ad18;
    font-weight: 600;
    border-top: 1px solid #e9e8e8;
}
.linkBtn {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 5px;
}
.excln {
    max-width: 70px;
    margin: 30px auto;
}
.excln img {
    width: 100%;
}
.min-width-400px {
    min-width: 400px;
}
.exclMark {
    width: 30px;
    height: 30px;
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 25px;
    background: url(assets/images/exclamation-mark.svg);
    background-repeat: no-repeat;
    margin-left: auto;
}
.exclMarkOuter {
    height: 100%;
    display: flex;
    align-items: center;
}

.unread {
    font-weight: 600;
}
.columnFlex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.action_array button[disabled] {
    opacity: 0.3 !important;
}
.min-width-820px .table-responsive {
    min-width: 820px;
}
.outerWrapWizard {
    margin: 0px -15px;
}
.radio {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    margin: 15px;
}
.radio .label {
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #c8ccd4;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
}
.radio .label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: var(--std_teel);
    transform: scale(0);
    transition: all 0.2s ease;
    opacity: 0.08;
    pointer-events: none;
}
.radio:hover .label:after {
    transform: scale(3.6);
}
input[type="radio"]:checked + .label {
    border-color: var(--std_teel);
}
input[type="radio"]:checked + .label:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
}
.cntr {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    width: 100%;
    text-align: center;
}
.hidden {
    display: none;
}
.credit {
    position: fixed;
    right: 20px;
    bottom: 20px;
    transition: all 0.2s ease;
    -webkit-user-select: none;
    user-select: none;
    opacity: 0.6;
}
.credit img {
    width: 72px;
}
.credit:hover {
    transform: scale(0.95);
}
.striped .mat-row:nth-child(odd) .label {
    background: #fff;
}
.striped .mat-row:nth-child(even) .label {
    background: rgb(212, 212, 212);
}
.mat-simple-snackbar-action {
    color: #fff;
}

.custom_mat_table td {
    border: 1px solid #f8f0f0;
    text-align: left;
    padding: 8px;
}
.custom_mat_table th {
    border: 1px solid #f8f0f0;
    font-size: 14px;
    padding: 15px;
}
table.custom_mat_table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.custom_mat_table tr:nth-child(even) {
    background-color: #dadada;
}
.custom_mat_table tr:nth-child(odd) {
    background-color: #fff;
}
.card_icons > img {
    width: 50px;
    margin-right: 14px;
}

.button-group-fl button {
    width: 100%;
    height: 100%;
    padding: 0 2px;
}
.h-85px {
    height: 85px;
}
.buttons.green {
    background: var(--std_green);
    color: #ffffff;
}

.buttons.red {
    background: #ec644b;
    color: #ffffff;
}
.custom-modalbox .mat-dialog-container {
    position: relative;
}

.custom-modalbox .modal_content {
    position: relative;
    min-width: 450px;
}
.textareaWithBorder {
    border: 1px solid #e6e5e5 !important;
    padding: 0px 5px;
    border-radius: 5px;
}
.w-desc {
    max-width: 300px;
}
.matToolTip {
    font-size: 13px;
}
.min-w-tc .mat-dialog-content {
    min-width: 1000px;
}
.card_head {
    padding: 10px;
}
.account_number P:nth-child(2) {
    margin-bottom: 5px;
}
/* .descriptionInput{
    padding: 5px;
    position: relative;
    border: 1px solid #e6e5e5;
    padding: 0px 5px;
    border-radius: 5px;
}
.descriptionInput textarea{
    margin: 10px 0px 15px 0px;
    width: 100%;
    border: none;
    background: none;
    resize: none;

} */

.error-mat {
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    color: #f44336;
}
.error-mat-undeline .mat-form-field-underline {
    background-color: rgba(216, 57, 46, 0.69) !important;
}
.scrtPwd .passwordIndicator {
    bottom: unset !important;
    top: 0;
}
.hyperColor {
    color: #007bff !important;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    /* width: 100%; */
}
.hyperColor:hover {
    text-decoration: underline;
}

.scrollTerms .terms:last-child {
    min-height: 55vh;
}

.cust_materror {
    font-size: 87%;
    /* font-size: 79%; */
    margin-top: 4px;
}

.tabnav li {
    cursor: pointer;
}
.blackListedIcon img {
    width: 100%;
}
.blackListedIcon {
    width: 35px;
    padding-left: 5px;
}
.no-data-bg {
    background-color: #fff;
}
.container_inner_load .circle:nth-child(2) {
    animation-delay: 0.2s;
}

.container_inner_load .circle:nth-child(3) {
    animation-delay: 0.4s;
}
.container_inner_load .circle:nth-child(4) {
    animation-delay: 0.6s;
}

.container_inner_load .circle:nth-child(5) {
    animation-delay: 0.8s;
}

.container_inner_load .circle {
    width: 5px;
    background: #d4ae2a;
    height: 5px;
    margin-left: 10px;
    border-radius: 50%;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.container_inner_load {
    display: flex;
    width: 100%;
    align-items: center;
}
@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
span.hashCode {
    display: block;
    word-break: break-all;
}

.success_green {
    border-color: green !important;
    color: green;
}

.newtrash {
    margin-right: 10px;
}
.NoData {
    margin: 0px 0px 10px;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #0d404d;
}
.NoDataFound {
    margin: 2.5rem 0px 0px;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #0d404d;
}
.null_status {
    opacity: 0.5;
}

th.heading_table_th {
    width: 320px;
}

.cntAreaHeight {
    height: calc(100% - 87px);
}

.twoFaTableWrap {
    display: flex;
    align-items: center;
}
.twoFaTableIcon {
    width: 30px;
}
.twoFaTableIcon img {
    width: 100%;
}
.tableIcon {
    width: 20px;
}
.twoFaTableTitle {
    height: calc(100% - 30px);
    padding-left: 10px;
}
.tabCounts {
    width: 23px;
    height: 23px;
    /* padding: 2px; */
    /* display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; */
    line-height: 22px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    /* text-align: center; */
    /* background: red; */
}

.interactiveIcons {
    transition: transform 0.2s;
    cursor: pointer;
}

.interactiveIcons:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.3);
}

.CustomMatdatePicker .mat-form-field-wrapper {
    margin-bottom: 0px !important;
}

/* comment Popup style */

/* .scrollCommentSection:hover{
    overflow: auto !important;
    margin-right: -6px;
} */

.chatHistory {
    overflow: hidden;
    margin-bottom: 25px;
}

.chat_flex {
    display: flex;
    align-items: flex-start;
}

.chatTextinput {
    border: 1px solid #e2dfe2;
    border-radius: 4px;
    position: relative;
    padding: 5px 15px 30px;
}
.propic_container {
    margin-right: 12px;
}

.dpCnr._comment {
    border-radius: 4px !important;
    width: 40px;
    height: 40px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.chatDetails {
    width: 95%;
    padding: 0px 10px;
}
.pastChatContainer .name {
    margin-right: 5px;
}
.pastChatContainer .name,
.pastChatContainer .time {
    font-size: 13px;
    font-family: AV-HEAVY;
}
.pastChatContainer .time {
    color: #928888;
    font-size: 12px;
}
.inlineTableCntrl {
    list-style: none;
    padding: 0 0 0 10px;
    margin: 0;
    min-width: 60px;
    text-align: right;
}
.inlineTableCntrl li {
    cursor: pointer;
    display: inline-block;
}
.inlineTableCntrl li.edit_row_disable {
    cursor: text;
}
.inlineTableCntrl li:not(:first-child) {
    margin-left: 5px;
}


.chatTextinput .chatInputArea {
    margin: 5px 0px 5px 0px;
    font-size: 14px;
    border: 0;
    width: 100%;
    padding-right: 20px;
}
.chatAttach {
    position: absolute;
    bottom: 10px;
    right: 10px;
    overflow: hidden;
}

.attachCover input[type="file"] {
    position: absolute;
    left: 0px;
    width: 100%;
    opacity: 0;
    z-index: 1;
}
.attach img{

    width: 25px;
  }
.attachCover img {
    width: 25px;
}

.table-sub-head {
    font-weight: bold;
    color: var(--std_teel);
    padding-left: 15px;
}
.chatMessge {
    color: #000;
    font-size: 15px;
    word-break: break-word;
}
.downloadContainer .attachClip img {
    width: 25px;
}
.downloadContainer {
    display: flex;
    align-items: center;
    padding: 6px;
    border: 1px solid #dcd7d7ee;
    border-radius: 4px;
    background: #eee;
}
.downloadContainer .ChatAttachName p {
    margin-bottom: 0;
}
.miniClose {
    cursor: pointer;
    color: #b5acac;
    margin-left: 10px;
}
.miniClose:hover {
    color: #f44336;
}
.chat_flex .chatMessge p {
    text-align: justify;
}
.commentsAttach {
    position: absolute;
    bottom: 5px;
    width: 100%;
    text-align: right;
    right: 0px;
    padding: 0px 30px;
    font-family: AVPB;
}

.commentAttachPos .long_text_container {
    width: 150px;
}
.badgeInline {
    width: calc(100% - 30px) !important;
    padding-right: 25px;
}
.badgeInline .mat-badge-content {
    top: 0px !important;
    right: 0 !important;
}
.mat-dialog-title .ModelTitle {
    color: var(--std_teel);
}
.node-block {
    background: #ecf0f0;
}

.block-chain {
    height: 130px;
    width: 120px;
    text-align: left;
    padding: 0px 20px;
    background: #ecf0f0;
    border-radius: 6px;
    box-shadow: -1px 1px 5px -2px rgba(89, 86, 89, 1);
    margin: 4px;
}

.block-chain {
    position: relative;
}

.block-chain h3 {
    font-size: 13px;
    margin-top: 45px;
}

.node-lists .mat-card-subtitle {
    margin: 0px !important;
}

.node-lists .button-container img {
    width: 25px;
}

.nodetitle {
    color: #000;
    font-size: 13.5px;
    font-weight: 600;
    font-family: AVR;
    word-break: break-all;
}

.nodesubtitle {
    font-size: 12px;
    font-family: AVB;
    font-weight: 700;
}

.mat_cust_head,
.card_head {
    border-bottom: 1px solid #eee;
}

.node_list_parent {
    height: 490px;
    overflow-y: scroll;
}

.dtl.card_individual .card-wrap:nth-child(3) .status-card {
    background: #e66969 !important;
    color: #fff;
}

.dtl.card_individual .card-wrap:nth-child(4) .status-card {
    background: #38a382 !important;
    color: #fff;
}

.dtl.card_individual .status-card p {
    min-height: 40px;
}

/* Node Details */
.node-details .mat-cell {
    padding: 5px 10px 5px 8px !important;
}

/* Custom Tab */

.scroll_cover {
    overflow-x: hidden;
    width: 96%;
    padding-bottom: 15px;
    align-items: center;
}

.tab_singles {
    min-width: 24%;
    padding: 12px 10px;
    position: relative;
    margin-right: 15px;
    background: #fafafb;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
}

.selected {
    position: relative;
    background: var(--std_teel);
    color: #fff;
}

.selected::after {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    left: calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    width: -moz-calc(50% - 10px);
    background: var(--std_teel);
    bottom: -10px;

    transform: rotate(45deg);
}

.tab {
    flex-wrap: wrap;
    padding: 15px;
}

.tab .semibold {
    font-size: 1em;
}

.tab .col-sm-3 {
    margin-bottom: 55px;
}

.scroller {
    align-items: center;
}



.cardbody {
    margin: 0px 25px;
    padding: 25px 0px;
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;
}

.tab .col-12 {
    margin: 15px 0px 30px 0px;
}

.tab .col-12 p {
    font-family: AV-HEAVY;
    font-size: 16px;
}

.dividerlinewrap {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
}

.order-table td,
.order-table .table thead th {
    border: 1px solid #e5ddddc7;
}

.order-table .table thead th {
    font-size: 1em;
    font-weight: 600;
    color: var(--std_teel) !important;
    padding: 15px 10px !important;
    background: #fafafb;
}

.mixed-td span img {
    height: 10px;
    width: 10px;
}

.divLine {
    width: 100%;
    height: 1px;
    background: #ebeaea;
}

td[rowspan] {
    background: #fff;
}

td[rowspan]:before {
    content: "";
    position: absolute;
    height: auto;
    width: 1px;
    background: #ebeaea;
    left: 8px;
    top: 20px;
    bottom: 20px;
}
.user_edits div:nth-child(2) button{
    border: 1px solid #d4ae2a;
}
.user_edits div:nth-child(2) button:nth-child(1) {
    border: 1px solid #0d404d;
    color: var(--std_teel);
    background-color: transparent !important;
    padding: 0px 20px !important;
}

.order-table .trans td[rowspan]:before {
    content: "";
    top: 23px;
    bottom: 23px;
}

.order-table td.trans-hash.date span {
    width: 180px;
}

.plainSelect {
    padding-left: 10px;
    padding-right: 10px;
    background: #fafafa;
    border: 1px solid #eee;
    border-radius: 5px;
    color: #144553;
}

.GraphData p {
    margin: 0;
    color: var(--std_teel);
}
.GraphDataCount {
    font-size: 20px;
    font-weight: bold;
}
.order-table td[rowspan]::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 1px;
    background: #ebeaea;
    left: 8px;
    top: 49%;
    bottom: 49%;
}

.tableTop_section {
    /* padding: 10px; */
    margin: 0 !important;
}

.tableTop_section .bold {
    font-size: 1em !important;
}

.mixed-td span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 0 0 6px;
}

.card_individual .peer-status .node-block,
.card_individual .dlt-mgmt .node-block {
    min-height: 145px;
}

.chaincode-export mat-tab-group.mat-tab-group.mat-primary {
    height: 300px;
}

/* Block Table */

.block-tab-head .ech-hed {
    font-size: 1em;
    font-weight: 600;
    color: var(--std_teel) !important;
    padding: 15px 10px !important;
    border: 1px solid #e5ddddc7;
    border-right: 0px;
}

.block-tab-head .ech-data {
    font-size: 14px;
    color: var(--std_teel) !important;
    padding: 10px 10px !important;
    border: 1px solid #e5ddddc7;
    border-right: 0px;
    min-height: 42px;
}

.ech-item-data:nth-child(odd) .ech-data {
    background: #fafafb;
}

.ech-item-data:nth-child(even) .ech-data {
    background: #ffffff;
}

.block-tab-head .hed-seven.ech-hed,
.block-tab-head .ech-data {
    border-right: 1px solid #e5ddddc7;
}

.block-tab-head .hed-one {
    width: 10%;
}

.block-tab-head .hed-two {
    width: 30%;
}

.block-tab-head .hed-three {
    width: 20%;
}

.block-tab-head .hed-four {
    width: 20%;
}

.block-tab-head .hed-five {
    width: 20%;
}

.block-tab-head .hed-six {
    width: 16%;
}

.block-tab-head .hed-seven {
    width: 20%;
}

.block-tab-head .hed-desc {
    width: 45%;
}

.blok-monitor .mat-accordion .mat-expansion-panel {
    border-radius: 0px !important;
}

.blok-monitor .blk-acc-head img {
    height: 10px;
    width: 10px;
    margin: 0 10px 0 0px;
}

.blok-monitor .hed-one.lineRow:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    border: 0.5px solid#e9e3e3;
    right: 0;
}

.blok-monitor .hed-one.lineRow:before {
    position: absolute;
    content: "";
    height: 48px;
    border: 0.5px solid #e9e3e3;
    bottom: 0;
}

.block-mon-pagination .mat-paginator-outer-container {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

::ng-deep .blok-monitor .mat-accordion .mat-expansion-panel-body {
    padding: 0px 10px !important;
    margin-bottom: 1.5rem !important;
}

td.mat-cell.stat-false {
    border-left: 10px solid #ec644b !important;
    position: relative;
}
td.mat-cell.stat-inProgress {
    border-left: 10px solid #d4ad18 !important;
    position: relative;
}

td.mat-cell.stat-true {
    border-left: 10px solid #26a65b !important;
    position: relative;
}

td.trans-hash span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

span.trans-id {
    display: block;
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

td.mat-cell.stat-false:before,
td.mat-cell.stat-true:before {
    content: "";
    position: absolute;
    background: #d4d4d4;
    height: 3px;
    width: 5px;
    left: -5px;
    bottom: -2px;
}

.chain-repo-tab tr.mat-footer-row,
.chain-repo-tab tr.mat-row {
    height: 35px !important;
}

.chain-repo-tab td.mat-cell {
    border: 2px solid #d4d4d4;
}
.gridCtrls select {
    padding: 8px 10px;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #ccc;
    min-width: 200px;
}
.blockScroller {
    overflow: auto;
    width: calc(100% - 60px);
    display: flex;
}


.leftAngle {
    background-image: url("assets/images/left-angle.svg");
}
.rightAngle {
    background-image: url("assets/images/right-angle.svg");
}
.blockScrollerOuter {
    padding-top: 15px;
}

.matIcon {
    color: var(--std_teel);
}
.titleColor {
    color: #9e9494;
}

.toAddress {
    color: var(--std_teel);
    font-size: 13px;
    height: 20px;
    font-family: AV-HEAVY;
}
.closeSearch {
    background-image: url("assets/images/close-red.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    width: 13px;
    height: 28px;
    cursor: pointer;
}

.searchResults {
    height: calc(100% - 60px);
    overflow: auto;
}
.gridCtrls label {
    margin: 0;
    font-size: 12px;
    color: var(--std_teel);
}
textarea :disabled {
    cursor: no-drop;
}

.copyright p {
    font-size: 14px;
    font-weight: 600;
    color: #080808;
    margin-bottom: 5px;
}
.contactUsMenu {
    display: flex;
    cursor: pointer;
    flex-direction: column;
}
.contactEmail,
.contactPhone {
    position: relative;
    padding-left: 35px;
    margin: 10px 0px;
    color: #212529;
}
.contactEmail,
.contactPhone:hover{
    text-decoration: none!important;
    color: #212529!important;
}
.contactEmail::before,
.contactPhone::before {
    width: 25px;
    height: 25px;
    background-size: 20px;
    background-position: center;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
}
.contactEmail::before {
    background-image: url(assets/images/email.svg);
}
.contactPhone::before {
    background-image: url(assets/images/phone.svg);
}
.tableRowEdit .disableAct {
    opacity: 0.3;
    cursor: no-drop !important;
    position: relative;
    cursor: no-drop;
}
.tableRowEdit .disableAct::after {
    content: "";
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0%;
}
.dot_ind p {
    font-weight: 100 !important;
    font-size: 13px !important;
    font-family: unset !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.custom_Errors .errorMessage {
    position: absolute;
    bottom: -25px;
    left: 0px;
}

.custom_Errors {
    position: relative;
}
.custom_Errors .errorMessage {
    font-size: 0.9em;
}

.custom_Errors .errorMessage.warningMessage {
    color: #d4ae2a;
}

.custom_Errors .errorMessage.warningMessage:before {
    background: url(./assets/images/warning-board.png) no-repeat 0px 4px;
    display: inline-block;
    width: 20px;
    height: 23px;
    content: "";
    background-size: 100%;
    vertical-align: -15%;
}
.inactiveTerm img {
    width: 100%;
}
.inactiveTerm {
    width: 18px;
    margin-left: auto;
}

.choosebutton input {
    max-width: 100%;
    left: 0px;
    height: 100%;
    opacity: 0;
    top: 0px;
    position: absolute;
    cursor: pointer;
}

.gridCtrls select.mini_select {
    min-width: 110px;
}

.highlightselect,
.highlightselect span {
    color: #d4ad19 !important;
    font-weight: 700 !important;
}
.input_with_button .edit img {
    width: 30px;
}
.popupTitle_rules + .ml-auto {
    display: flex;
}
.re-verify {
    background: url(assets/images/reverify.svg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    border: 0;
}
.float-left {
    float: left !important;
}
span.eTax:after,
span.cTax:after {
    padding: 5px;
    border-radius: 50%;
    width: 25px;
    display: flex;
    height: 25px;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 5px;
    font-weight: bold;
}
span.eTax:after {
    content: "E";
    background: #d4b033;
    color: #fff;
}
span.cTax:after {
    content: "C";
    background: green;
    color: #fff;
}
span.eTax,
span.cTax {
    display: flex;
    align-items: center;
    margin: 1px 0px;
}
.emptyTax {
    padding-right: 30px;
}
.cpIndicator.dot_ind ul {
    justify-content: unset !important;
}
.cpIndicator.dot_ind ul li {
    margin-right: 15px;
}
.cpIndicator.dot_ind ul li::before {
    display: none !important;
}
.cpIndicator.dot_ind p {
    padding-left: 10px;
}
.bs {
    width: 25px;
    height: 25px;
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    line-height: 24px;
    font-size: 12px;
}
.button button:disabled {
    background: #dedddd !important;
    color: #585858 !important;
}
.pb-33 {
    padding-bottom: 33px;
}
.marginZeroForm .mat-form-field-wrapper {
    margin: 0 !important;
}

@-moz-document url-prefix() {
    .custom_Errors .errorMessage {
        font-size: 0.76em;
    }
}

.amount_sapn {
    display: block;
    width: 100%;
    text-align: right;
}

.limit_table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.limit_height {
    max-height: 600px;
    overflow: auto !important;
}
.commentAttachPos {
    display: flex;
    justify-content: flex-end;
}
.maxWidthFeeText,
.maxWidthFeeBtn {
    padding: 0px 25px 0px 0px;
}
.fileNameCnr {
    display: flex;
}
.fileNameCnr .fileName {
    color: #092b34;
}
.fileNameCnr .clearFile {
    background: url(assets/images/close.svg);
    width: 15px;
    height: 15px;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 5px;
    cursor: pointer;
}

.filterIcon img {
    width: 25px !important;
}
.sub-head-cover {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.sub-head-cover .sub_headings {
    width: 40%;
}

.sub-head-cover .sub_headings .bold {
    font-size: 1.2em;
}
.sub-head-cover .semibold {
    font-size: 1em;
}

.bc-seperate table {
    border-collapse: separate;
}
.delete-sm h3 {
    font-size: 1.2em;
    max-width: 427px;
    text-align: center;
}
.btnNormal {
    color: #fff !important;
}

.redbold {
    color: red;
    font-weight: 600;
}

.highlighText {
    font-weight: 600;
}
.barChartCover {
    padding-bottom: 24px;
    padding-top: 24px;
}
.scrollling_table {
    border-collapse: separate;
    padding: 0 !important;
}
.h-300 {
    height: 300px;
    min-height: 300px;
    overflow: scroll;
}
.termsTabs .mat-tab-body-content {
    overflow: hidden !important;
}
.matRadioBtnGrp mat-radio-button:not(:last-child) {
    margin-right: 5px;
}
.canIconCnr img {
    width: 20px;
    margin: 0px 5px;
}
.transparentButton {
    opacity: 0.2;
    cursor: no-drop !important;
    outline: none !important;
}
.smFont h3 {
    font-size: 1.3em;
}
.eyeview {
    cursor: pointer;
}

#loading {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(13, 64, 77, 0.3);
    border-radius: 50%;
    border-top-color: #0d404d;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
.no-found{
    text-align: center;
    font-weight: bold;
    color: #0d404d;
    font-size: 15px;
}


.el_label label{
    margin-bottom: 0px !important;
    font-size: 10px;
    color: #3e707dab
  }
  .end-center {
    justify-content: flex-end!important;
    align-items: center;
    width: 100%;
}
.FileFilter{
    margin-right: 1rem;
}
.FileFilter .mat-radio-button{
    margin-right: 1rem;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #10404d!important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #10404d!important;
}
.pii_badge{
    background-color: #d4b033;
    color: white !important;
    padding: 1px 7px;
    border-radius: 3px;
    cursor: pointer;
}
.symbol{
  width: 30px;
  display: inline-block;
}
.no_found{
    text-align: center;
    padding: 15px 0px;
    font-weight: bold;
    color: black;
}
.virus-icon{
    width: 2rem;
    margin-left: 10px;
}
.noCursor{
    opacity: 0.6;
    cursor: no-drop;
}
.info_img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_img img {
  width: 15px;
  margin-left: 10px;
}

.imgTick{
    width: 21px !important;
    height: 21px !important;
}
.shared-icon{
    width: 17px !important;
    filter: invert(17%) sepia(56%) saturate(754%) hue-rotate(146deg) brightness(97%) contrast(90%);
    margin: 0px 5px;
}
.pdf-ellipsis{
    font-family: AVR;
    color: gray;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 0px 3px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
  }
  button:disabled{
      opacity: 0.8 !important;
  }
  .ph-icon{
    width: 20px !important;
    margin-right: 5px !important;
    display: inline-block !important;
    vertical-align: top !important;
  }
  .ph-icon img{
    width: 100% !important;
  }
  .mat-tooltip{
    word-break: break-all !important;
 }

.tooltip-multiline {
  white-space: pre-line;
}

.tableRowEdit .sendReminder::before {
    content: '';
    background-image: url(assets/images/email.svg);
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    top: -1px;
}

.tableRowEdit .siteCancel::before {
    content: '';
    background-image: url(assets/images/cancel.png);
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    top: -1px;
}

.tableRowEdit .verified::before {
    content: url(assets/images/check-line.svg);
    width: 20px;
    height: 20px;
    position: relative;
    left: -4px;
    top: -3px;
}

.tableRowEdit .disabled::after {
    content: "";
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0%;
}

.tableRowEdit .disabled {

    opacity: .3;
    cursor: no-drop !important;
    position: relative;
    cursor: no-drop;
}

.tableRowEdit .rowReject::before {
    content: url(assets/images/rowreject.svg);
    width: 12px;
    height: 12px;
    position: relative;
    margin-right: 8px;
    top: -3px;
}

.tableRowEdit .viewDocument::before {
    content: '';
    background-image: url(assets/images/view_document.png);
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    top: -1px;
}

.tableRowEdit .siteDownload::before {
    content: '';
    background-image: url(assets/images/download.svg);
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    top: -1px;
}

.cdk-global-overlay-wrapper {
    display: grid !important;
    place-items: center !important;
  }
  .dynamic-profile-container {
    padding: 7px;
    text-align: center;
    width: 32px !important;
    height: 32px !important;
    border-radius: 20px;
    color: white;
  }

.grecaptcha-badge { visibility: hidden; }


::ng-deep .mat-simple-snackbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  input::-ms-reveal {
    display: none;
}